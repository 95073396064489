// Angular
import { Injectable } from '@angular/core';
// Sentry
import * as Sentry from '@sentry/angular';
// Environment
import { environment } from '@environment';
// RxJs & Lodash
import { noop } from 'lodash';
import { BehaviorSubject } from 'rxjs';
// Service
import { GlobalErrorHandler } from './global-error-handler.service';
// Model
import { ISentryUserIdentity } from '@shared/models';
// Package.JSON
import packageInfo from 'package.json';

@Injectable()
export class SentryService {

    // Public
    loadingSubject = new BehaviorSubject<boolean>(false);
    loading$ = this.loadingSubject.asObservable();

    /**
     * Servce constructor
     * @param globalErrorHandler: GlobalErrorHandler
     */
    constructor(
        private readonly globalErrorHandler: GlobalErrorHandler
    ) {
        environment.isSentryLoggingEnable ? this.initSentry() : noop();
    }

    /**
     * Initialise the Sentry Logging
     */
    public initSentry(): void {
        Sentry.init({
            dsn: environment.sentryDsn,
            maxValueLength: 500,
            environment: environment.production ? 'PROD' : 'DEV',
            release: `${packageInfo.name}-v${packageInfo.version}`,
            beforeSend(event) {
                // Check if the event has an exception and if it has the 'values' array
                if (event.exception && event.exception.values && event.exception.values.length > 0) {
                    const errorMessage = event.exception.values[0].value;

                    // List of errors you want to ignore
                    const ignoredErrors = [
                        'Timeout',
                        'Non-Error exception captured',
                        '0 Unknown Error',
                        'ChunkLoadError',
                        'InvalidStateError',
                        'Error: NG02100',
                        'Object.prototype.hasOwnProperty.call(e,"telephone")',
                        "'text/html' is not a valid JavaScript MIME type",
                        "Invalid call to runtime.sendMessage()",
                        "Old view transition aborted by new view transition.",
                        "Identifier 'change_ua' has already been declared",
                        "Non-Error promise rejection captured with value: undefined",
                        "TypeError: Failed to fetch dynamically imported module",
                        "InvalidStateError: Skipping view transition because viewport size changed.",
                        "View transition was skipped because document visibility state is hidden.",
                        "Unexpected token 'else'"
                    ];

                    // Check if the error message contains any of the ignored errors
                    if (errorMessage && ignoredErrors.some(ignoredError => errorMessage.includes(ignoredError))) {
                        return null; // Prevents the event from being sent to Sentry
                    }
                }

                return event; // Otherwise, send the event to Sentry
            },
        });
    }

    /**
     * Set User identity information to sentry for more detail view
     * @param payload: ISentryUserIdentity
     */
    public setUser(payload: ISentryUserIdentity): void {
        const scope = Sentry.getCurrentScope();
        scope.setUser(payload);
    }

    /**
     * Set Tags to sentry issue
     * @param tag: string
     * @example https://docs.sentry.io/enriching-error-data/additional-data/#tags
     */
    public setTag(key: string, value: string): void {
        const scope = Sentry.getCurrentScope();
        scope.setTag(key, value);
    }

    /**
     * Rest Sentry config when user logged out
     */
    public resetAll(): void {
        const scope = Sentry.getCurrentScope();
        scope.setUser({ email: '' });
    }

    /**
     * Open Sentry Report Dialog Modal
     * @param user: user details
     */
    public openFeedbackDialog(user: any): void {
        try {
            const eventId: string = Sentry.captureMessage(`${user.id} | ${user.name} | ${user.email}  | Reported Technical issue`);
            const option = {
                eventId,
                title: 'Report Technical Issue',
                subtitle: 'Our team will be notified. If you would like to help, tell us what happened below.',
                subtitle2: '',
                labelSubmit: 'Report Issue',
                user: {
                    email: user.email,
                    name: user.name
                },
                onLoad: () => {
                    this.loadingSubject.next(false);
                }
            };
            Sentry.showReportDialog(option);
        } catch (error) {
            this.globalErrorHandler.handleError(error);
        }
    }
}
